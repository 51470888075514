.header {
    background-color: white;
    position: fixed;
    display: flex;
    padding: 1.6rem;
    right: 0;
    left: 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        top: 0;
    background: #282828;
    transition: all .3s cubic-bezier(.4,0,.2,1);
    z-index: 5;
}


.header-inner-section{
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    color: azure;
    justify-content: space-between;
    display: flex;
   }

   .menu-link-item{
    text-decoration: none !important;
    color: white !important;
   }

.logo{
    width: 10%;
    height: fit-content;
    margin: 0px;
    align-self: center;
 cursor: pointer;
}



.menu-links{
    display: flex;
    gap: 10px;
}

.menu-links > li{
    list-style: none;

}


.menu-link{
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
}

.menu-link:hover{
    color: gray;
}

