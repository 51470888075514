body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:where(.css-dev-only-do-not-override-1x0dypw).ant-divider-horizontal.ant-divider-with-text {
  display: flex;
  align-items: center;
  margin: 16px 0;
  color: white !important;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-block-start: 0 rgba(5, 5, 5, 0.06);
}


.ant-collapse>.ant-collapse-item >.ant-collapse-header .ant-collapse-header-text {
  margin-inline-end: auto;
  color: white !important;
}


.ant-collapse>.ant-collapse-item >.ant-collapse-header .ant-collapse-arrow svg {
  transition: transform 0.3s;
  color: white !important;
}
.ant-collapse .ant-collapse-content {
  color: #fff !important;
  background-color: #333 !important;
  border-top: 1px solid #d9d9d9;
}

:where(.css-1x0dypw).ant-divider .ant-divider-inner-text {
  display: inline-block;
  color: white !important;
  padding-block: 0;
  padding-inline: 1em;
}