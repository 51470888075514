.hero-section {
    position: relative;
    width: 100%;
    height: 100vh;
    /* overflow: hidden; */
    justify-content: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: white;
    font-family: Arial, sans-serif;
  }

  .cc-soon {
    width: 21%;
}
  
  .hero-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    /* z-index: -2; */
    display: block;
    background: none;
  }
  
  .gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: 
    linear-gradient(300deg, rgb(72 30 111) 0%, rgba(0, 232, 200, 0) 45%), linear-gradient(300deg, rgba(0, 232, 200, 0.6) 0%, rgba(0, 232, 200, 0) 80%), linear-gradient(0deg, rgba(51, 51, 51, 0.7) 100%, rgba(51, 51, 51, 0.7) 100%)  }
  
  .hero-content {
    position: relative;
    margin-left: 10%;
    margin-right: 10%;
width: 100%;
gap: 12rem;
margin-top: 10rem;
align-items: center;
    display: flex;
    z-index: 1;
  }
  
  .hero-heading {
    font-size: 39px;
    margin-top: 0px;
    max-width: 670px;
    font-weight: 400;
    margin-bottom: 4rem;
}
  
  .hero-description {
    font-size: 16px;
    color: #fff;
    opacity: .7;
    margin-bottom: 1.5rem;
  }
  
  .hero-button {
    align-items: center;
    border: none;
    width: fit-content;
    font-weight: 700;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .hero-button:hover {
  }
  
  .arrow-icon {
    margin-left: 0.5rem;
    font-size: 1.2rem;
  }
  
  .info-box {
    margin-top: 2rem;
    padding: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.6);
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    font-size: 1rem;
  }
  

  .coming-soon{
color: red;
display: none;
  }

  .cc-soon{

  }

  .view-teams-btn{
    margin-top: 2%;
  }


  .hero-content-left{
    display: flex;
    text-align: left;
    flex-direction: column;

  }

  .hero-content-right{
    max-width: 250px;
    border: 5px solid #fff;
    align-self: flex-start;
    padding: 40px;
        height: 200px;
    width: 50%;
    text-align: left;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }


  .arrow_ic {
    width: 8%;
}


  .latest-news-title{
    opacity: .7;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 1.5;
    text-transform: uppercase;
    color: #fff;
    font-weight: 400;
  }

  .go-beyound-text{

  }


  .hero_extras{
    height: 100%;
  }