.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #222222;
        color: #fff;
    border: none;
    transition: 2s;
    border-radius: 50%;
    width: 1rem;
    height: 4.2rem;
    font-size: 20px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex
;
    justify-content: center;
    align-items: center;
}