.view-teams-btn{
    background: #8903ff !important;
    color: white;

}

.reach_out_section {
    background-color: #282828;
    height: 70vh;
    padding-left: 7rem;
    padding-right: 7rem;
    display: none !important;
    align-items: center;
    display: flex;
    gap: 20px;
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.call-to-action {
    width: 100%;
    /* text-align: left; */
    height: -moz-fit-content;
    height: fit-content;
    align-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 55px;
    background-color: #222;
}

.call-to-action-text{
    text-align: left;
    display: flex;
    height: fit-content;
    flex-direction: column;
    border: 5px solid #b902b9;
padding: 55px;
    width: 100%;
}

.findout {
    font-size: 35px;
    width: 48rem;
    color: white;
    font-weight: 400;
}
.cta-button{
    display: inline-block;
    padding: 20px 35px;
    border: 1px solid hsla(0, 0%, 100%, .35);
    text-decoration: none;
    color: #fff;
    font-size: 13px;
    background-color: transparent;

    font-weight: 600;
    margin-top: 2rem;
    line-height: 1;
    letter-spacing: 2px;
    width: fit-content;
    text-transform: uppercase;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.cta-button:hover{
    background-color: white;
    color: #333;
}

.socials-cta{
    font-size: 35px;
    color: white;
    font-weight: 400;
}

.socials-cta-2{
    color: #fff;
    font-size: 16px;

}