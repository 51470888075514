.footer_section{
    background-color: #222222;
    display: flex ;

    flex-direction: column;
}

.footer-top{
    justify-content: space-between;
    display: flex;
}

.footer-links{
    text-decoration: none !important;
    color: white !important;
}

.footer-top-left{
    padding-left: 8rem;
    padding-top: 4rem;
    display: flex;
    gap: 10px;
    padding-bottom: 4rem;
    text-align: left;
}
.footer-top-left > p{
    color: white;
    font-weight: 400;
    
}



.subscribe-text{
    color: white;
    font-size: 35px;
    font-weight: 400;
}
.mail-ic{
width: 20%;
}

.footer-top-right{
    padding-top: 4rem;
    padding-right: 8rem;
    padding-bottom: 4rem;
}

.sisi-mail{
    color: white;
}

.footer-logo{
    width: 10%;
}

.lower-footer{
    display: flex;
    flex-direction: column;
}

.lower-footer-top{
    display: flex;
    padding-left: 8rem;
    padding-right: 8rem;
    justify-content: space-between;
}

.link-list{
    display: flex;
    gap: 10px;
}

.link-list > ul{
    margin-top: 20px;
    color: #8a8a8a;
    font-size: 13px;
    line-height: 2;
    text-decoration: none;
}

.bottom-footer-text{
    text-align: right;
    color: #8a8a8a;
    font-size: 13px;
    line-height: 2;
    padding-right: 8rem;

}