.body-about{
    /* height: 100vh; */
}

.about-page{
    background-color: #333;
}


.ant-collapse-header-text{
    text-align: left !important;
}
.ant-collapse-content-box > p{
    text-align: left !important;
}


.about-image{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-size: cover;
    background-attachment: scroll;

    width: 100%;
    gap: 20px;
    background-image: url("../media/ht.jpg");
}

.left-about{
    height: 100%;
    width: 100%;
    background-color: transparent;

}

.right-about{
    height: 100%;
    justify-content: center;
    width: 100%;
    padding-right: 7rem;
    text-align: left;
    display: flex;
    flex-direction: column;
/* background-color: green; */
}

.right-about-title {
    color: white;
    font-size: 28px;
    font-weight: 400;
    font-variant: all-small-caps;
}

.about-list > ul{
color: white !important;
}

.solutions{
    display: flex;
        height: 100%;
        justify-content: center;
    padding-bottom: 5rem;
    }

.solution-grid{
    width: 55%;
margin-top: 3rem;
}

.solutions-heading{
    font-size: 35px;
    color:white;
    font-weight: 400;
    margin-top: 5rem;
}

.about-list-2 > ul {
    text-align: left !important;
}