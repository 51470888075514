.ata-section{
display: flex;

}


.ata-section-right{
display: flex;
flex-direction: column;
}


.ata-section {
  height: 100vh;
    display: flex;
    justify-content: space-between; /* Add space between left and right sections */
    align-items: center; /* Center content vertically */
    width: 100%; /* Full width of the page */
    padding: 0 7rem; /* Add horizontal padding for better spacing */
    box-sizing: border-box; /* Ensure padding doesn't overflow */
  }
  
  .ata-section-left {
    flex: 1; /* Take up equal space with the right section */
    text-align: left; /* Align text to the left */
    max-width: 40%; /* Limit the width of the left section */
  }
  
  .ata-section-right {
    flex: 1; /* Take up equal space with the left section */
    text-align: center; /* Center-align the animated text */
    max-width: 40%; /* Limit the width of the right section */
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
  }
  
  .ot_subtitle {
 
    font-size: 50px;
    /* margin-bottom: 40px; */
    font-weight: 400;
    margin-bottom: 10px;
    color: #ffffff; /* Adjust as needed */
  }
  
  .ot_subs {
    font-size: 16px;
    color: #ffffff;
    line-height: 1.6;
    opacity: .5;
  }
  
  .ata-section-right div {
    color: #ffffff;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    font-size: 18px;
    width: 100%; /* Ensure the text container spans the full width */
    overflow-wrap: break-word; /* Prevent text from overflowing */
    text-align: center; /* Ensure the text is centered */
  }
  

  .cut{
    width: 100%;
  }


  .upsaler {
    background-color: #333;
    height: 100vh;
    padding-left: 14rem;
    display: flex
;
    padding-right: 14rem;
}


  .upsaler-left{
    align-content: center;
    width: 100%;
    text-align: left;
  }

  .upsaler-right{
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;

  }

  .upsaler-title-one{
    font-size: 16px;
    color: #ffffff;
    line-height: 1.6;
    opacity: .5;
  }

  .read_more{
    display: inline-block;
    padding: 20px 35px;
    border: 1px solid hsla(0, 0%, 100%, .35);
    text-decoration: none;
    color: #fff;
    font-size: 13px;
    background-color: transparent;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 2px;
    text-transform: uppercase;
    transition: all .2s ease-in-out;
    cursor: pointer;
  }

  .read_more:hover{
    background-color: white;
    color: #333;
  }


  .cross-cutting-text{
    font-size: 50px;
    max-width: 450px;
    margin-bottom: 40px;
    color: white;
    font-weight: 400;
  }

  .cct-subText{
    color: white;
    font-size: 16px;
    
    margin-bottom: 4rem;
  }


  .bars{
    /* align-self: center; */
    width:70%;
    margin-top: 5rem;
    align-self: flex-end;

}



.home-inner-element{
  background-color: #282828;
  height: 100vh;
  /* background-image: url("../media/vt.jpeg"); */
  align-items: center;
  padding-left: 14rem;
  align-content: center;
  padding-top: 4rem;
  padding-right: 14rem;
}

.hie-box{
  height: 70%;
  background-size: cover;
  background-image: url("../media/vt.jpeg");

}

.overlay-bg{
  height: 100%;
  display: flex;
  justify-content: center;
    align-items: center;
  background: linear-gradient(300deg, rgb(72 30 111) 0%, rgba(0, 232, 200, 0) 45%), linear-gradient(300deg, rgba(0, 232, 200, 0.6) 0%, rgba(0, 232, 200, 0) 80%), linear-gradient(0deg, rgba(51, 51, 51, 0.7) 100%, rgba(51, 51, 51, 0.7) 100%);
}

.overlay-hts{
  height: 100%;
  display: flex;
  justify-content: center;
    align-items: center;
    padding-left: 7rem;
    padding-right: 7rem;
    grid-gap: 80px;
    background-color: #333333d6; 
}

.left-ol{
  /* background-color: red; */
  height: fit-content;
  text-align: left;
  /* padding: 1rem; */

}

.left-ol2{
  height: fit-content;

}

.catch-phrase{
  font-size: 40px;
    max-width: 50rem;
    line-height: 1.3;
    color: #fff;
    margin-bottom: 40px;
}

.line{
  background-color: white;
  width: 80%;
  display: flex;
  justify-self: center;
  align-self: center;
  height: 1px;
}



.line-btn{
  display: inline-block;
    padding: 20px 35px;
    border: 1px solid hsla(0, 0%, 100%, .35);
    text-decoration: none;
    color: #fff;
    font-size: 13px;
    background-color: transparent;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 2px;
    text-transform: uppercase;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.line-btn:hover{
  background-color: hsla(0, 0%, 100%, .35);
  color: #333;
}


.home-team-section{
/* height: 100vh; */
background-attachment:scroll;
background-attachment: fixed;
background-color: red;
background-image: url("../media/nets.jpg");
}



.right-box {
  border-style: solid;
  border-color: purple;
  height: 500px;
  margin-top: 7rem;
  margin-bottom: 7rem;
  border-width: thick;
  width: 100%;
}

.left-box{
  display: flex;
  text-align: left;
  padding-left: 7rem;
  flex-direction: column;
}

.reachout-btn{
  width: fit-content;
}

.reachout-btn:hover{
  background-color: transparent;
  color: #fff;
}


.box-career-text{
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 1.5;
  text-transform: uppercase;
  color: #717171;
  font-weight: 400;
  margin: 0 0 40px;
color: #fff;
}

.box-heading{
  font-size: 50px;
  color: white;
}

.box-desc {
  font-size: 16px;
  color: white;
  width: 90%;
  margin-bottom: 1.5rem;
  line-height: 1.6;
  font-weight: 400;
}




