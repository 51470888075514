.hero_extras{

}

.hero_extras_content{
    display: flex;
    justify-content: center;
    padding: 120px 0;
}

.hec_title{
    max-width: 80rem;
    color: white;
    align-self: center;
    font-size: 40px;
    margin-bottom: 0px;
    font-weight: 400;
}

.hec_span{
   color: #b902b9;
  
}